<template>
  <div class="mb-[100px]">
    <div v-show="merchantData.is_self_signup && merchantData.name === 'Entrepreneur Merchant'" class="pb-6 px-2 mb-10" id="mainDiv">
      <div class="flex items-center justify-between pb-4 border-0 border-b border-solid border-gainsboro">
        <p class="font-medium md:text-[32px] text-2xl text-black">{{ $t('Complete Your Profile') }}</p>
        <span class="material-symbols-rounded cursor-pointer text-[32px] text-stone-cold" @click="redirectToDashboard()">close</span>
      </div>
      
      <div class="mt-6 grid md:grid-cols-3 grid-cols-2 gap-[16px]">
        <div class="col-span-2 flex flex-col gap-[16px]">
          <div class="border border-solid rounded-lg px-5 pt-6 pb-[38px] border-gainsboro">    
            <p class="text-black font-medium md:text-[20px] text-[16px]">{{ $t('Company Details') }}</p>
            <p class="text-black font-medium md:text-[14px] text-[12px] mt-6">{{ $t('Fill out your profile details to ship your first order') }}</p>
            <div class="grid gap-6 mt-4 md:grid-cols-2 grid-cols-1">
              <div>
                <shipblu-input v-validate="'required|max:20'" name="company name" :labelPlaceholder="$t('Company Name')" :required="true" autocomplete="nofill" v-model="merchant.name" type="text" class="w-full" />
                <p class="md:text-[12px] text-[10px] text-black mt-2">{{$t('Company Name will be displayed on the shipping label')}}</p>
                <p class="text-danger text-sm" v-show="errors.has('company name')">{{ $t('Company Name is required') }}</p>
              </div>

              <div>
                <shipblu-input v-validate="'required|url'" name="store url" :labelPlaceholder="$t('Website or Social Media Link')" :required="true" autocomplete="nofill" v-model="merchant.store_url" type="text" class="w-full" />
                <p class="text-danger text-[12px]" v-show="errors.has('store url')">{{ $t('Website or Social Media Link is required') }}</p>
              </div>

              <div class="flex gap-3">
                <div class="w-2/3">
                  <shipblu-input v-validate="'required'" name="street and building" :labelPlaceholder="$t('Street and Building')" :required="true" autocomplete="nofill" v-model="merchant.address.line_1" type="text" class="w-full" />
                  <p class="text-danger text-sm" v-show="errors.has('street and building')">{{ $t('Street and Building are required') }}</p>
                </div>
                <div class="w-1/3">
                  <shipblu-input v-validate="'required'" name="flat no" :labelPlaceholder="$t('Flat No.')" :required="true" autocomplete="nofill" v-model="merchant.address.line_2" type="text" class="w-full" />
                  <p class="text-danger text-sm" v-show="errors.has('flat no')">{{ $t('Flat No. is required') }}</p>
                </div>
              </div>

              <div>
                <div @click="handleBlur" v-if="showDropdown === true" class="fixed inset-0 bg-transparent"></div>
                <div class="relative">
                  <span @click="showDropdown = !showDropdown" class="absolute right-0 top-1/2 text-bauhaus mx-4 flex items-center" style="transform: translateY(-50%);">
                    <feather-icon class="w-4 cursor-pointer" :icon="showDropdown ? 'ChevronUpIcon' : 'ChevronDownIcon'"></feather-icon>
                  </span>
                  <p v-if="!shipmentOldZone && !searchQuery" class="z-[-1] absolute top-1/2 left-[14px] flex gap-[1px]" style="transform: translateY(-50%);">
                    <span class="text-industrial-revolution">{{ $t('Zone') }}</span>
                    <span class="text-fluorescent-red text-lg leading-none">*</span>
                  </p>
                  <input type="text" name="zone" v-validate="'required'" v-model="searchQuery" @focus="handleFocus" :class="shipmentOldZone || searchQuery || showDropdown ? 'input-placeholder-active' : 'input-placeholder'"
                    :placeholder="shipmentOldZone && shipmentOldZone" class="h-[48px] bg-transparent p-4 rounded-md border border-solid w-full md:text-[14px] text-[12px] input-placeholder" autocomplete="off" />
                  <ul v-show="showDropdown" class="absolute w-full bg-lightgray border border-solid rounded-[5px] border-grey mt-[4px] py-[16px] px-[12px] overflow-y-auto z-[99993]" :style="{ maxHeight: dropdownMaxHeight }">
                    <li v-if="filteredZones.length === 0" class="font-medium md:text-[14px] text-[12px] text-center text-black">
                      {{ $t('Sorry, no matching option') }}
                    </li>
                    <li v-else v-for="region in filteredZones.filter(item => item.code !== 'NOGOV')" :key="region.id">
                      <div class="font-medium md:text-[14px] text-[12px] cursor-pointer flex items-center gap-1 text-industrial-revolution">
                        <span class="whitespace-nowrap py-[2px]">{{ $t(region.name) }}</span>
                        <div class="w-full h-[1px] bg-cold-morning"></div>
                      </div>
                      <ul v-for="city in region.cities" :key="city.id">
                        <li>
                          <div class="font-medium text-black md:text-[14px] text-[12px] cursor-pointer flex justify-between py-[2px] my-[4px]" @click="toggleCity(city)">
                            {{ $t(city.name) }}
                            <feather-icon :icon="city === expandedCity ? 'ChevronDownIcon' : $route.params.lang === 'ar' ? 'ChevronLeftIcon' : 'ChevronRightIcon'" svgClasses="w-5 h-5"/>
                          </div>
                          <ul v-show="city === expandedCity" class="px-[8px]">
                            <li v-for="zone in city.zones" :key="zone.id" @click="selectZone(zone)" class="cursor-pointer font-medium text-black md:text-[14px] text-[12px] py-[2px] my-[4px]">
                              {{ $t(zone.name) }}
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <p class="text-danger text-sm" v-show="errors.has('zone')">{{ $t('Zone is required') }}</p>
              </div>

              <div>
                <shipblu-input v-validate="'required|numeric'" name="monthly volume" :labelPlaceholder="$t('Monthly Volume')" :required="true" autocomplete="nofill" v-model="merchant.committed_volume" type="text" class="w-full" />
                <p class="text-danger text-sm" v-show="errors.has('monthly volume')">{{ $t('Monthly Shipping Volume is required') }}</p>
              </div>

              <div>
                <div @click="handleIndustryBlur" v-if="showIndustryDropdown === true" class="fixed inset-0 bg-transparent"></div>
                <div class="relative">
                  <span @click="showIndustryDropdown = !showIndustryDropdown" class="absolute right-0 top-1/2 text-bauhaus mx-4 flex items-center" style="transform: translateY(-50%);">
                    <feather-icon class="w-4 cursor-pointer" :icon="showIndustryDropdown ? 'ChevronUpIcon' : 'ChevronDownIcon'"></feather-icon>
                  </span>
                  <p v-if="!industryOld && !industrySearchQuery" class="z-[-1] absolute top-1/2 left-[14px] flex gap-[1px]" style="transform: translateY(-50%);">
                    <span class="text-industrial-revolution">{{ $t('Business Industry') }}</span>
                    <span class="text-fluorescent-red text-lg leading-none">*</span>
                  </p>
                  <input type="text" name="industry" v-validate="'required'" v-model="industrySearchQuery" @focus="handleIndustryFocus" :class="(merchant.industry && merchant.industry.value) || industrySearchQuery || showIndustryDropdown ? 'input-placeholder-active' : 'input-placeholder'"
                    :placeholder="merchant.industry && merchant.industry.value && merchant.industry.text" class="h-[48px] bg-transparent p-4 rounded-md border border-solid w-full md:text-[14px] text-[12px] input-placeholder" autocomplete="off" />
                  <ul v-show="showIndustryDropdown" class="absolute w-full bg-lightgray border border-solid rounded-[5px] border-grey mt-[4px] overflow-y-auto z-[99993]" :style="{ maxHeight: dropdownMaxHeight }">                    
                    <li v-if="filteredIndustries.length === 0" class="px-[12px] font-medium md:text-[14px] text-[12px] text-center text-black">
                      {{ $t('Sorry, no matching option') }}
                    </li>
                    <li v-else v-for="industry in filteredIndustries" :key="industry.id" class="px-[12px] py-[8px] font-medium md:text-[14px] text-[12px] cursor-pointer text-black" @click="selectIndustry(industry)">
                      {{ industry.text }}
                    </li>
                  </ul>
                </div>
                <p class="text-danger text-sm" v-show="errors.has('industry')">{{ $t('Business Industry is required') }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="md:col-span-1 col-span-2 flex flex-col gap-6 border border-solid border-gainsboro rounded-lg px-5 py-6">
          <p class="font-medium md:text-[20px] text-[16px] text-black">{{ $t('Company Logo') }}</p>
          <div class="flex items-center border-2 dashed-border">
            <input type="file" @change="uploadImage" class="hidden" ref="fileInput" accept="image/*">
            <div              
              @click="$refs.fileInput.click()"
              @drop="handleDrop"
              @dragover="handleDragover"
              @dragenter="handleDragover"
              class="m-auto lg:py-16 py-13 cursor-pointer text-center">
              <feather-icon icon="UploadIcon" svgClasses="h-10 w-10 text-night-snow" class="block" />
              <p class="text-primary text-sm" @click.stop="$refs.fileInput.click()">{{$t('Drag and drop your image')}}</p>
              <p class="text-xs text-silver" @click.stop="$refs.fileInput.click()">{{$t('Supports .png/.jpeg/.jpg formats')}}</p>
              <div class="w-full m-auto text-center mb-3" v-if="progress < 100">
                <vs-progress :percent="progress" color="primary"></vs-progress>
              </div>
              <div v-else class="text-center mt-3">
                <p class="text-xs text-black">{{ imageName }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="secondDiv" class="fixed bottom-0 z-[99992] pt-[24px] pb-[32px] flex justify-end gap-3 border-0 border-t border-solid border-gainsboro bg-layout">
      <vs-button class="sm:text-lg text-sm sm:flex-grow-0 flex-grow font-semibold px-6" color="#8599B3" @click="redirectToDashboard()">{{ $t('Cancel') }}</vs-button>
      <vs-button class="sm:text-lg text-sm sm:flex-grow-0 flex-grow font-semibold" @click="updateMerchant()">{{ $t('Submit') }}</vs-button>
    </div>
  </div>
</template>

<script>
import ShipbluInput from '@/layouts/components/ShipbluInput.vue'
import staticZones from '../../assets/utils/geo.json'
import common from '../../assets/utils/common'
import { sendRequest } from '../../http/axios/requestHelper'

export default {
  data () {
    return {
      zones: staticZones,
      showDropdown: false,
      searchQuery: '',
      shipmentOldZone: '',
      expandedCity: null,
      industries: [],
      merchant: {
        industry: { text: '', value: '' },
        address: {
          line_3: '',
          line_1: '',
          line_2: '',
          zone: {
            id: null,
            name: ''
          }
        }
      },
      merchantData: {},
      userMerchant: {},
      file_url: '',
      progress: 0,
      myProgress: 0,
      showIndustryDropdown: false,
      industrySearchQuery: '',
      industryOld: '',
      dropdownMaxHeight: 0,
      imageName: '',
      pickupPoint: {},
      returnPoint: {}
    }
  },
  computed: {
    filteredZones () {
      if (!this.searchQuery) return this.zones
      const query = this.searchQuery.toLowerCase()
      return this.zones
        .map(region => {
          const matchingCities = (region.cities || [])
            .map(city => {
              const matchingZones = (city.zones || []).filter(zone => zone.name.toLowerCase().includes(query))
              return matchingZones.length || city.name.toLowerCase().includes(query) ? { ...city, zones: matchingZones } : null
            })
            .filter(city => city)
          return matchingCities.length || region.name.toLowerCase().includes(query) ? { ...region, cities: matchingCities } : null
        })
        .filter(region => region)
    },
    filteredIndustries () {
      if (!this.industrySearchQuery) return this.industries
      const query = this.industrySearchQuery.toLowerCase()
      return this.industries.filter((industry) => industry.text.toLowerCase().includes(query))
    }
  },
  watch: {
    '$route.params.lang' () {
      this.industries = common.getIndustries(this)
    },
    'progress' (val) {
      if (val === 95) {
        clearInterval(this.myProgress)
      }
    }
  },
  methods: {
    handleIndustryFocus () {
      this.showIndustryDropdown = true
      this.industryOld = this.merchant.industry.text || ''
      this.industrySearchQuery = ''
      this.$validator.reset('industry')
      this.adjustDropdownHeight('industry')
    },
    handleIndustryBlur () {
      if (this.industryOld) {
        this.industrySearchQuery = this.industryOld
        this.merchant.industry.text = this.industryOld
      } else {
        this.industrySearchQuery = ''
        this.merchant.industry = { text: '', value: '' }
      }
      this.showIndustryDropdown = false
    },
    selectIndustry (industry) {
      const selectedIndustry = this.filteredIndustries.find(ind => ind.text.toLowerCase() === industry.text.toLowerCase())
      if (selectedIndustry) {
        this.merchant.industry = selectedIndustry
        this.industrySearchQuery = selectedIndustry.text
      } else if (this.industryOld) {
        this.industrySearchQuery = this.industryOld
        const oldIndustry = this.industries.find(ind => ind.text.toLowerCase() === this.industryOld.toLowerCase())
        this.merchant.industry = oldIndustry || { text: '', value: '' }
      } else {
        this.industrySearchQuery = ''
        this.merchant.industry = { text: '', value: '' }
      }
      this.showIndustryDropdown = false
    },
    uploadImage () {
      const formData = new FormData()
      const file = this.$refs.fileInput.files[0]
      formData.append('old_file', undefined)
      formData.append('new_file', this.$refs.fileInput.files[0])
      this.progress = 0
      this.startFakeProgress()
      sendRequest(true, false, this, 'api/v1/upload-file/', 'patch', formData, true,
        (response) => {
          this.file_url = response.data.file_url
          this.progress = 100
          clearInterval(this.myProgress)
          this.imageName = file.name
        }
      )
    },
    updateMerchant () {
      this.$validator.validateAll().then(result => {
        if (result && this.merchant.address && this.merchant.address.zone && this.merchant.address.zone.id !== 284) {
          const merchantObj = {
            display_name: this.merchant.name,
            name:this.merchant.name,
            address: {
              id: this.merchantData.address.id,
              line_1: this.merchant.address.line_1,
              line_2: this.merchant.address.line_2,
              line_3: this.merchant.address.line_3,
              zone: this.merchant.address.zone.id
            },
            committed_volume: this.merchant.committed_volume,
            store_url: this.merchant.store_url,
            industry: this.merchant.industry.value
          }
          if (this.file_url !== '') {
            merchantObj.logo = this.file_url
          }
          sendRequest(false, false, this, `api/v1/merchants/${this.userMerchant.merchant}/?company_details=true`, 'patch', merchantObj, true,
            (response) => {
              this.updateProfileIsCompleted()
              localStorage.removeItem('merchant')
              localStorage.setItem('merchant', JSON.stringify(response.data))
            }
          )
        }
      })
    },
    updateProfileIsCompleted () {
      sendRequest(false, false, this, `api/v1/merchants/${this.userMerchant.merchant}/profile-completed/`, 'patch', {}, true,
        () => {
          this.updatePickupPoint()
        }
      )
    },
    updatePickupPoint () {
      const addressObj = {
        address: {
          id: this.pickupPoint.address.id,
          ...this.merchant.address
        }
      }
      addressObj.address.zone = this.merchant.address.zone.id
      sendRequest(false, false, this, `api/v1/merchants/${this.userMerchant.merchant}/pickup-points/${this.pickupPoint.id}/`, 'patch', addressObj, true,
        () => {
          this.updateReturnPoint()
        }
      )
    },
    updateReturnPoint () {
      const addressObj = {
        address: {
          id: this.pickupPoint.address.id,
          ...this.merchant.address
        }
      }
      addressObj.address.zone = this.merchant.address.zone.id
      sendRequest(false, false, this, `api/v1/merchants/${this.userMerchant.merchant}/return-points/${this.returnPoint.id}/`, 'patch', addressObj, true,
        () => {
          this.redirectToDashboard()
          this.merchant = {
            address: {}
          }
        }
      )
    },
    handleFocus () {
      this.showDropdown = true
      this.showIndustryDropdown = false
      this.shipmentOldZone = this.searchQuery
      this.searchQuery = ''
      this.$validator.reset('zone')
      this.adjustDropdownHeight('zone')
    },
    handleBlur () {
      if (!this.selectedZone) {
        this.searchQuery = ''
      }
      if (this.shipmentOldZone && this.shipmentOldZone.length > 0) {
        this.searchQuery = this.shipmentOldZone
        this.merchant.address.zone.name = this.shipmentOldZone
      }
      this.showDropdown = false
    },
    toggleCity (city) {
      this.expandedCity = this.expandedCity === city ? null : city
    },
    selectZone (zone) {
      const city = this.expandedCity
      const region = this.zones.find(region => region.cities.some(cityItem => cityItem.id === city.id))
      this.searchQuery = `${this.$t(region.name)} - ${this.$t(city.name)} - ${this.$t(zone.name)}`
      this.merchant.address.zone = zone
      this.showDropdown = false
    },
    handleDragover (e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    handleDrop (e) {
      e.stopPropagation()
      e.preventDefault()
      const files = e.dataTransfer.files
      if (files.length !== 1) {
        this.$vs.notify({
          title: 'Login Attempt',
          text: 'Only support uploading one file!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }
      const rawFile = files[0] // only use files[0]
      if (!this.isImage(rawFile)) {
        this.$vs.notify({
          title: 'Login Attempt',
          text: 'Only supports upload .png, .jpg suffix files',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return false
      }
    },
    isImage (file) {
      return /\.(png|jpg)$/.test(file.name)
    },
    redirectToDashboard () {
      this.$router.push({
        name: 'merchant-dashboard'
      }).then(() => { this.$router.go(0) })
    },
    adjustDropdownHeight (type) {
      const scrollContainer = document.getElementById('scrollContainer')
      const zoneInput = document.querySelector('input[name="zone"]')
      const industryInput = document.querySelector('input[name="industry"]')
      if (scrollContainer && zoneInput && industryInput) {
        const inputRect = type === 'zone' ? zoneInput.getBoundingClientRect() : industryInput.getBoundingClientRect()
        const containerRect = scrollContainer.getBoundingClientRect()
        const scrollBottom = scrollContainer.scrollHeight
        const inputTopRelativeToContainer = inputRect.top - containerRect.top + scrollContainer.scrollTop
        const availableHeight = scrollBottom - inputTopRelativeToContainer
        const padding = 16
        const dropdownTopMargin = 4
        const maxHeight = availableHeight - padding - dropdownTopMargin - inputRect.height
        this.dropdownMaxHeight = `${Math.max(maxHeight, 100)}px`
      }
    },
    loadPickupPoints () {
      sendRequest(false, false, this, `api/v1/merchants/${this.userMerchant.merchant}/pickup-points/`, 'get', null, true,
        (response) => {
          this.pickupPoint = response.data.results[0]
        }
      )
    },
    loadReturnPoints () {
      sendRequest(false, false, this, `api/v1/merchants/${this.userMerchant.merchant}/return-points/`, 'get', null, true,
        (response) => {
          this.returnPoint = response.data.results[0]
        }
      )
    },
    startFakeProgress () {
      this.myProgress = setInterval(() => {
        if (this.progress < 90) { 
          const increment = Math.max(1, Math.floor((95 - this.progress) / 10))
          this.progress += increment
        }
      }, 200)
    }
  },
  mounted () {
    const mainDiv = document.getElementById('mainDiv')
    const secondDiv = document.getElementById('secondDiv')
    const syncWidths = () => {
      if (mainDiv && secondDiv) {
        secondDiv.style.width = `${mainDiv.offsetWidth}px`
      }
    }
    syncWidths()
    const resizeObserver = new ResizeObserver(syncWidths)
    if (mainDiv) {
      resizeObserver.observe(mainDiv)
    }
  },
  components: {
    ShipbluInput
  },
  beforeCreate () {
    const merchant = common.checkMerchantInLocalStorage(this)
    merchant.then(results => {
      this.userMerchant = results.userMerchantData
      this.merchantData = results.merchantData
      if (!(this.merchantData.is_self_signup && this.merchantData.name === 'Entrepreneur Merchant')) {
        this.$router.push({
          name: 'merchant-dashboard'
        })
      }
    })
  },
  created () {
    this.industries = common.getIndustries(this)
    const merchant = common.checkMerchantInLocalStorage(this)
    merchant.then(results => {
      this.userMerchant = results.userMerchantData
      this.merchantData = results.merchantData
      this.loadPickupPoints()
      this.loadReturnPoints()
    })
  }
}
</script>

<style lang="scss">
.bg-lightgray {
  background-color: #f8f8f8;
}
.input-placeholder {
  border: 1px solid #BFBFBF;
  &::placeholder {
    font-size: 14px;
    color: #737373 !important;
  }
}
.input-placeholder:focus {
  outline: 1px solid #1C5BFE;
  border: 1px solid #1C5BFE !important;
}
.dashed-border {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23CCD9FF' stroke-width='2' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 8px;
}
</style>
